import type {
  Asset,
  ContentfulAssetFragment,
  ContentfulImageFragment,
  DeliveryImagePropertiesFragment,
  ProductContentfulImage,
} from '@business/gql/graphql';
import Image from '@ui/components/Image/Image';
import { ClassNameValue } from 'tailwind-merge';
import * as styles from './ContentfulImage.styles';

type ContentfulImageProps = {
  className?: ClassNameValue;
  desktopImage?:
    | ContentfulImageFragment
    | ContentfulAssetFragment
    | ProductContentfulImage
    | DeliveryImagePropertiesFragment
    | Omit<Asset, 'contentfulMetadata' | 'sys'>
    | null;
  mobileImage?:
    | ContentfulImageFragment
    | ContentfulAssetFragment
    | ProductContentfulImage
    | DeliveryImagePropertiesFragment
    | Omit<Asset, 'contentfulMetadata' | 'sys'>
    | null;
  sizes?: string;
  priority?: boolean;
  fill?: boolean;
  blurDataURL?: string;
  breakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

export default function ContentfulImage({
  className,
  mobileImage,
  desktopImage,
  sizes,
  priority,
  fill,
  blurDataURL,
  breakpoint = 'sm',
}: ContentfulImageProps) {
  return (
    <>
      {desktopImage?.url && (
        <Image
          className={styles.desktopImageContainer({
            breakpoint: mobileImage?.url ? breakpoint : 'default',
            className,
          })}
          src={desktopImage.url}
          alt={desktopImage.title ?? ''}
          sizes={sizes}
          priority={priority}
          fill={fill}
          blurDataURL={blurDataURL}
          width={desktopImage.width ?? undefined}
          height={desktopImage.height ?? undefined}
        />
      )}
      {mobileImage?.url && (
        <Image
          className={styles.mobileImageContainer({
            breakpoint,
            className,
          })}
          sizes={sizes}
          src={mobileImage.url}
          alt={mobileImage.title ?? ''}
          priority={priority}
          fill={fill}
          blurDataURL={blurDataURL}
          width={mobileImage.width ?? undefined}
          height={mobileImage.height ?? undefined}
        />
      )}
    </>
  );
}
