import { tv } from 'tailwind-variants';

export const desktopImageContainer = tv({
  base: '',
  variants: {
    size: {
      mobile: 'max-sm:hidden',
      default: '',
    },
    breakpoint: {
      xs: 'max-xs:hidden',
      sm: 'max-sm:hidden',
      md: 'max-md:hidden',
      lg: 'max-lg:hidden',
      xl: 'max-xl:hidden',
      default: '',
    },
  },
});
export const mobileImageContainer = tv({
  base: '',
  variants: {
    size: {
      mobile: 'sm:hidden',
      default: '',
    },
    breakpoint: {
      xs: 'xs:hidden',
      sm: 'sm:hidden',
      md: 'md:hidden',
      lg: 'lg:hidden',
      xl: 'xl:hidden',
      default: '',
    },
  },
});
